import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-144f9f9e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "source"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  var _component_Pagination = _resolveComponent("Pagination");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_table, {
    data: _ctx.tableData,
    style: {
      "width": "100%"
    },
    "empty-text": "暂无数据",
    class: "el-table-index"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        width: "440"
      }, {
        default: _withCtx(function (scoped) {
          return [_createElementVNode("span", _hoisted_1, _toDisplayString(scoped.row.launchType == 2 ? _ctx.source : ""), 1), _createElementVNode("span", {
            style: _normalizeStyle({
              color: scoped.row.status == 3 ? '#f09104' : '',
              cursor: 'default'
            })
          }, _toDisplayString(scoped.row.title), 5)];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        prop: "startTime",
        width: "150"
      }), _ctx.tabsIndex == 0 ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0
      }, {
        default: _withCtx(function (scoped) {
          return [_createElementVNode("span", {
            class: "btn-detail state-start",
            onClick: function onClick($event) {
              return _ctx.gosignUp(scoped.row);
            }
          }, "开始考试", 8, _hoisted_2)];
        }),
        _: 1
      })) : (_openBlock(), _createBlock(_component_el_table_column, {
        key: 1
      }, {
        default: _withCtx(function (scoped) {
          return [_createElementVNode("span", {
            class: "btn-detail state-detail",
            onClick: function onClick($event) {
              return _ctx.gosignUp(scoped.row);
            }
          }, "查看详情", 8, _hoisted_3), _createElementVNode("span", {
            class: _normalizeClass(scoped.row.code == 2 ? 'state-miss' : 'state-finished')
          }, _toDisplayString(scoped.row.code == 2 ? "缺考" : "已完成"), 3)];
        }),
        _: 1
      }))];
    }),
    _: 1
  }, 8, ["data"]), _createVNode(_component_Pagination, {
    pagesize: 10,
    "total-num": _ctx.totalNum,
    onChangPage: _ctx.changPage
  }, null, 8, ["total-num", "onChangPage"])]);
}