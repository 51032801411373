import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3492fb59"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  var _component_Pagination = _resolveComponent("Pagination");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_table, {
    "show-header": false,
    data: _ctx.tableData,
    style: {
      "width": "100%"
    },
    "empty-text": "暂无数据",
    class: "el-table-index"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        width: "400"
      }, {
        default: _withCtx(function (scoped) {
          return [_withDirectives(_createElementVNode("span", {
            class: "source"
          }, _toDisplayString(_ctx.myRecord), 513), [[_vShow, scoped.row.originator == _ctx.userId]]), _withDirectives(_createElementVNode("span", {
            class: "source"
          }, _toDisplayString(_ctx.source), 513), [[_vShow, scoped.row.launchType == 2]]), _createElementVNode("span", null, _toDisplayString(scoped.row.title), 1)];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        prop: "startTime",
        width: "150"
      }), _createVNode(_component_el_table_column, null, {
        default: _withCtx(function (scoped) {
          return [_createElementVNode("span", {
            class: "btn-signUp",
            onClick: function onClick($event) {
              return _ctx.gosignUp(scoped.row.trainId);
            }
          }, _toDisplayString(_ctx.tabsIndex == '0' ? '去报名' : '查看详情'), 9, _hoisted_1)];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["data"]), _createVNode(_component_Pagination, {
    pagesize: 10,
    "total-num": _ctx.totalNum,
    onChangPage: _ctx.changPage
  }, null, 8, ["total-num", "onChangPage"])]);
}