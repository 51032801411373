import _objectSpread from "/Users/beixinyuan/Documents/www/newParty1/party-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref, reactive, toRefs, watch } from "vue";
import examList from "@/views/exam/components/List.vue";
import trainList from "@/views/train/components/List.vue";
import meetingList from "@/views/meeting/components/List.vue";
import activityList from "@/views/activity/components/List.vue";
import questionnaireList from "@/views/questionnaire/components/List.vue";
import thoughtList from "@/views/thought/components/List.vue";
import workPlanList from "@/views/workPlan/components/List.vue";
import investigateList from "@/views/investigate/components/List.vue";
import annualCheckList from "@/views/annualCheck/components/List.vue";
import democracyList from "@/views/democracy/components/List.vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    examList: examList,
    trainList: trainList,
    meetingList: meetingList,
    activityList: activityList,
    questionnaireList: questionnaireList,
    thoughtList: thoughtList,
    workPlanList: workPlanList,
    investigateList: investigateList,
    annualCheckList: annualCheckList,
    democracyList: democracyList
  },
  props: {
    tabsIndex: {
      type: Number,
      default: 0
    },
    typeValue: {
      type: Number,
      default: 1
    }
  },
  emits: ["update:totalNum"],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var userId = useStore().state.user.user.userId; //获取用户id
    // 分页属性    

    var totalNum = ref(0); // 接口传递的参数

    var conditions = reactive({
      launchType: 2
    });
    var entity = reactive({
      table1: null,
      table2: null,
      table3: null,
      table4: null,
      table5: null,
      table6: null,
      table7: null,
      table8: null,
      table9: null,
      table10: null
    });

    function getList() {
      entity['table' + props.typeValue].getTabledata();
    }

    watch(function () {
      return totalNum.value;
    }, function (val) {
      emit("update:totalNum", val);
    });
    return _objectSpread({
      // 数量限制
      totalNum: totalNum,
      conditions: conditions,
      userId: userId,
      getList: getList
    }, toRefs(entity));
  }
});