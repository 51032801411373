import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  var _component_Head = _resolveComponent("Head");

  var _component_list = _resolveComponent("list");

  var _component_Tabs = _resolveComponent("Tabs");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "责任清单"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_select, {
        modelValue: _ctx.typeValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.typeValue = $event;
        }),
        class: "dj-select",
        placeholder: "请选择分类",
        onChange: _ctx.selectChange
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (item) {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.typeId,
              label: item.typeName,
              value: item.typeId
            }, null, 8, ["label", "value"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue", "onChange"])];
    }),
    _: 1
  }), _createVNode(_component_Tabs, {
    title: _ctx.titleArr,
    onChangeOther: _ctx.changeOther
  }, {
    content0: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table1",
        totalNum: _ctx.totalNum,
        "onUpdate:totalNum": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.totalNum = $event;
        }),
        totalNumModifiers: {
          sync: true
        },
        "tabs-index": 0,
        "type-value": _ctx.typeValue
      }, null, 8, ["totalNum", "type-value"]), [[_vShow, _ctx.tabsIndex == 0]])];
    }),
    content1: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table2",
        "tabs-index": 1,
        "type-value": _ctx.typeValue
      }, null, 8, ["type-value"]), [[_vShow, _ctx.tabsIndex == 1]])];
    }),
    _: 1
  }, 8, ["title", "onChangeOther"])], 64);
}