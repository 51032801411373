import _objectSpread from "/Users/beixinyuan/Documents/www/newParty1/party-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_examList = _resolveComponent("examList");

  var _component_trainList = _resolveComponent("trainList");

  var _component_meetingList = _resolveComponent("meetingList");

  var _component_activityList = _resolveComponent("activityList");

  var _component_questionnaireList = _resolveComponent("questionnaireList");

  var _component_thoughtList = _resolveComponent("thoughtList");

  var _component_workPlanList = _resolveComponent("workPlanList");

  var _component_investigateList = _resolveComponent("investigateList");

  var _component_annualCheckList = _resolveComponent("annualCheckList");

  var _component_democracyList = _resolveComponent("democracyList");

  return _openBlock(), _createElementBlock("div", null, [_ctx.typeValue == 1 ? (_openBlock(), _createBlock(_component_examList, {
    key: 0,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _ctx.conditions,
    "api-name": "IntegratedExamHistoryMylist"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 2 ? (_openBlock(), _createBlock(_component_trainList, {
    key: 1,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex + 1,
    conditions: _ctx.conditions,
    "api-name": _ctx.tabsIndex == 1 ? 'getReadyStartTrain' : 'getReadyStartTrain'
  }, null, 8, ["totalNum", "tabs-index", "conditions", "api-name"])) : _createCommentVNode("", true), _ctx.typeValue == 3 ? (_openBlock(), _createBlock(_component_meetingList, {
    key: 2,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex + 1,
    conditions: _ctx.conditions,
    "api-name": "getReadyStart"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 4 ? (_openBlock(), _createBlock(_component_activityList, {
    key: 3,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex + 1,
    conditions: _ctx.conditions,
    "api-name": _ctx.tabsIndex == 1 ? 'getReadyStartActivity' : 'getReadyStartActivity'
  }, null, 8, ["totalNum", "tabs-index", "conditions", "api-name"])) : _createCommentVNode("", true), _ctx.typeValue == 5 ? (_openBlock(), _createBlock(_component_questionnaireList, {
    key: 4,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _ctx.conditions,
    "api-name": "getQuestList"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 6 ? (_openBlock(), _createBlock(_component_thoughtList, {
    key: 5,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _ctx.conditions,
    "api-name": "getThoughtList"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 7 ? (_openBlock(), _createBlock(_component_workPlanList, {
    key: 6,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[6] || (_cache[6] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _ctx.conditions,
    "api-name": "workselectList"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 8 ? (_openBlock(), _createBlock(_component_investigateList, {
    key: 7,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[7] || (_cache[7] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _ctx.conditions,
    "api-name": "getVoteList"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 9 ? (_openBlock(), _createBlock(_component_annualCheckList, {
    key: 8,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[8] || (_cache[8] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    "top-tabs-index": 0,
    conditions: _ctx.conditions,
    "api-name": "getEvaluationSelfPage"
  }, null, 8, ["totalNum", "tabs-index", "conditions"])) : _createCommentVNode("", true), _ctx.typeValue == 10 ? (_openBlock(), _createBlock(_component_democracyList, {
    key: 9,
    ref: 'table' + _ctx.typeValue,
    totalNum: _ctx.totalNum,
    "onUpdate:totalNum": _cache[9] || (_cache[9] = function ($event) {
      return _ctx.totalNum = $event;
    }),
    "tabs-index": _ctx.tabsIndex,
    conditions: _objectSpread({
      userId: _ctx.tabsIndex == 0 ? undefined : _ctx.userId
    }, _ctx.conditions),
    "api-name": _ctx.tabsIndex == 0 ? 'mydemocracy' : 'myhisdemocracy'
  }, null, 8, ["totalNum", "tabs-index", "conditions", "api-name"])) : _createCommentVNode("", true)]);
}